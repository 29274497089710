define("discourse/plugins/discourse-gamification/discourse/templates/connectors/user-card-metadata/gamification-score", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if user.gamification_score}}
    <span class="desc">{{i18n "gamification.score"}} </span>
    <span>{{gamification-score model=user}}</span>
  {{/if}}
  */
  {
    "id": "rr2VMPNb",
    "block": "[[[41,[30,0,[\"user\",\"gamification_score\"]],[[[1,\"  \"],[10,1],[14,0,\"desc\"],[12],[1,[28,[35,1],[\"gamification.score\"],null]],[1,\" \"],[13],[1,\"\\n  \"],[10,1],[12],[1,[28,[35,2],null,[[\"model\"],[[30,0,[\"user\"]]]]]],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `user` property path was used in the `discourse/plugins/discourse-gamification/discourse/templates/connectors/user-card-metadata/gamification-score.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.user}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `user` property path was used in the `discourse/plugins/discourse-gamification/discourse/templates/connectors/user-card-metadata/gamification-score.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.user}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\",\"gamification-score\"]]",
    "moduleName": "discourse/plugins/discourse-gamification/discourse/templates/connectors/user-card-metadata/gamification-score.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});